import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconShower = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="30"
      height="30"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#000000"
          d="M4,23a1,1,0,0,0,1-1V8A5,5,0,0,1,15,8V9.1A5.009,5.009,0,0,0,11,14v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V14a5.009,5.009,0,0,0-4-4.9V8A7,7,0,0,0,3,8V22A1,1,0,0,0,4,23Zm15-9v1H13V14a3,3,0,0,1,6,0Zm-3,4a1,1,0,0,1,1,1v3a1,1,0,0,1-2,0V19A1,1,0,0,1,16,18Zm5,1v3a1,1,0,0,1-2,0V19a1,1,0,0,1,2,0Zm-9-1a1,1,0,0,1,1,1v3a1,1,0,0,1-2,0V19A1,1,0,0,1,12,18Z"
        ></path>
      </g>
    </svg>
  );
};

IconShower.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShower.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShower;
