import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconBedrooms = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="30"
      height="30"
      fill="#000000"
      viewBox="0 0 24 24"
      id="bedroom-6"
      data-name="Flat Line"
      xmlns="http://www.w3.org/2000/svg"
      class="icon flat-line"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          id="secondary"
          d="M5,13H19a2,2,0,0,1,2,2v2a0,0,0,0,1,0,0H3a0,0,0,0,1,0,0V15A2,2,0,0,1,5,13Z"
          fill="none"
          strokeWidth={2}
        ></path>
        <path
          id="primary"
          fill="none"
          strokeWidth={2}
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5,17v2m14-2v2M15,7h0a2,2,0,0,0-2-2H11A2,2,0,0,0,9,7H5v6H19V7Zm4,0V5M5,7V5M21,15a2,2,0,0,0-2-2H5a2,2,0,0,0-2,2v2H21Z"
        ></path>
      </g>
    </svg>
  );
};

IconBedrooms.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBedrooms.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBedrooms;
