import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../../util/types';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  ResponsiveImage,
  IconNextArrow,
  IconPrevArrow,
  Modal,
  Button,
} from '../../../components';
import Slider from 'react-slick';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';

import css from './ListingImageGallery.module.css';
import { isArrayLength } from '../../../util/genericHelpers';
import IconRightArrow from '../../../components/IconRightArrow/IconRightArrow';
import IconBackArrow from '../../../components/IconBackArrow/IconBackArrow';

const ListingImageGallery = props => {
  const { intl, images, imageVariants, viewport, onManageDisableScrolling = () => false } = props;
  const [showAll, onShowAll] = useState(false);
  const items = images.map((img, i) => {
    return {
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });
  const isMobileLayout = viewport?.width > 0 && viewport?.width < 768;
  const renderItem = item => {
    return (
      <AspectRatioWrapper width={1} height={1} className={css.itemWrapper} isListingPage={true}>
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
          />
        </div>
      </AspectRatioWrapper>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  function RenderNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconRightArrow className={css.icon} />
      </div>
    );
  }

  function RenderPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconBackArrow className={css.icon} />
      </div>
    );
  }

  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    fade: true,
    infinite: true,
    lazyLoad: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    // customPaging: function(i) {
    //   const item = isArrayLength(items) && items[i];
    //   return renderItem(item);
    // },
  };

  const renderSliderData = (
    <>
      {!isMobileLayout ? (
        <div className={css.gallaryDesktopSec}> {items?.slice(0, 3).map(renderItem)}</div>
      ) : (
        <Slider className={css.gallarySec} {...settings}>
          {items.map(renderItem)}
        </Slider>
      )}
    </>
  );

  const renderShowAllModal = (
    <Modal
      usePortal
      id="ListingImageGallery"
      modalTitle={`View All (${items?.length})`}
      isOpen={showAll}
      onClose={() => onShowAll(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      isGallaryModal={true}
    >
      <div className={css.modalGallarySlider}>
        <Slider className={css.gallarySec} {...settings}>
          {items.map(renderItem)}
        </Slider>
      </div>
    </Modal>
  );

  return (
    <div className={css.gallarySlider}>
      {renderSliderData}
      <div className={css.viewPhotos}>
        <Button onClick={() => onShowAll(!showAll)}>Show All ({items?.length})</Button>
        {renderShowAllModal}
      </div>
    </div>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,
  thumbnailVariants: arrayOf(string),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
