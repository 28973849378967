import React from 'react';
import { H4 } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import IconGuest from '../../components/IconGuest/IconGuest';
import IconBedrooms from '../../components/IconBedrooms/IconBedrooms';
import IconShower from '../../components/IconShower/IconShower';
import IconShare from '../../components/IconShare/IconShare';
import IconHeart from '../../components/IconHeart/IconHeart';

import css from './ListingPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../ducks/user.duck';
import { isAuthenticatedSelector } from '../../ducks/auth.duck';
import { getWishlist, isArrayLength } from '../../util/genericHelpers';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import IconHeartFilled from '../../components/IconHeartFilled/IconHeartFilled';
import { getLastElementFromAddress } from '../../components/ListingCard/ListingCard';

import guestsIcon from './ListingIcons/guests.png';
import bedroomsIcon from './ListingIcons/bedrooms.png';
import bedsIcon from './ListingIcons/beds.png';
import showerIcon from './ListingIcons/shower.png';

function SectionHeading(props) {
  const { setIsSocialSharingModalOpen, richTitle, publicData, id } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const wishlist = getWishlist(currentUser);
  const routeConfiguration = useRouteConfiguration();

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateCurrentUser = array => {
    const params = { wishlist: Array.from(new Set(array)) };

    return onUpdateProfile({
      publicData: { ...params },
    });
  };

  return (
    <>
      {/* <div className={css.host}>
        <span>
          <IconGuest /> Superhost
        </span>
      </div> */}
      <div className={css.listingPageHeader}>
        <div className={css.listingPageHeaderLeft}>
          <H4 as="h1" className={css.orderPanelTitle}>
            <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
          </H4>
          <p>
            <span className={css.location}>
              {getLastElementFromAddress(publicData?.location?.address)}
            </span>
          </p>{' '}
          <div className={css.roomDetailSec}>
            <span>
              <img src={guestsIcon} alt="Guests" />
              {publicData?.maxPeople || publicData?.guests} Guests
            </span>
            <span className={css.beds}>
              <img src={bedroomsIcon} alt="Bedrooms" />
              {publicData?.beds} Bedrooms
            </span>
            <span className={css.beds}>
              <img src={bedsIcon} alt="Beds" />
              {publicData?.beds} Beds
            </span>
            <span>
              <img src={showerIcon} alt="Baths" />
              {publicData?.bathrooms} Baths
            </span>
          </div>
        </div>
        <div className={css.listingPageHeaderRight}>
          {/* <button className={css.shareBtn} onClick={() => setIsSocialSharingModalOpen(true)}>
            <IconShare />
          </button> */}
          <button
            className={css.saveBtn}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (isAuthenticated) {
                const index = wishlist && wishlist.findIndex(b => b == id);
                if (id) {
                  if (index > -1) {
                    wishlist && wishlist.splice(index, 1);
                    const removedwishlist = Array.from(new Set(wishlist));
                    onUpdateCurrentUser(removedwishlist);
                  } else {
                    wishlist.push(id);
                    onUpdateCurrentUser(Array.from(new Set(wishlist)));
                  }
                }
              } else {
                history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}));
              }
            }}
          >
            {isArrayLength(wishlist) && wishlist.findIndex(e => e == id) > -1 ? (
              <IconHeartFilled />
            ) : (
              <IconHeart />
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default SectionHeading;
