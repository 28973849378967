import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import css from './ListingPage.module.css';
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import { H4, Icons } from '../../components';

const settings = {
  slidesToShow: 3,
  speed: 500,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 667,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function AboutBedroomSection({ title, publicData }) {
  const sliderItems = [
    { label: 'Check-in', value: publicData?.checkin, icon: <Icons name="clockIcon" /> },
    { label: 'Check-out', value: publicData?.checkout, icon: <Icons name="clockIcon" /> },
    {
      label: 'Cleaning Fee',
      value: '$' + (publicData?.cleaningFee / 100).toFixed(2),
      icon: <Icons name="dollarIcon" />,
    },
    {
      label: 'Damage Fee',
      value: '$' + (publicData?.damageFee / 100).toFixed(2),
      icon: <Icons name="dollarIcon" />,
    },
    {
      label: 'Pet Fee',
      value: '$' + (publicData?.petFee / 100).toFixed(2),
      icon: <Icons name="dollarIcon" />,
    },
  ];

  return (
    <div className={css.aboutBedroom}>
      <H4 as="h1" className={css.orderPanelTitle}>
        More about {title}
      </H4>
      <div className={css.sliderSec}>
        <Slider {...settings}>
          {sliderItems.map((item, index) => (
            <div key={index} className={css.bedDetails}>
              {item?.icon}
              <h2>{item.label}</h2>
              <p>{item.value}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

AboutBedroomSection.propTypes = {
  publicData: PropTypes.shape({
    checkin: PropTypes.string,
    checkout: PropTypes.string,
    cleaningFee: PropTypes.number,
    damageFee: PropTypes.number,
    petFee: PropTypes.number,
  }).isRequired,
};

export default AboutBedroomSection;
