import React from 'react';
import Slider from 'react-slick';
import { H4 } from '../../components';
import IconRightArrow from '../../components/IconRightArrow/IconRightArrow';

import css from './ListingPage.module.css';
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import { capitalizeFirstLetter } from '../../util/genericHelpers';

const gallerySettings = {
  slidesToShow: 1,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
};

function RulesBlock({ title, children }) {
  return (
    <div className={css.rulesBlock}>
      <h2>{title}</h2>
      {children}
    </div>
  );
}

function generateRulesContent(publicData) {
  const {
    eventsAllowed = '',
    smokingAllowed = '',
    petsAllowed = '',
    mimimumStay = '',
    maximumStay = '',
    ageRequirement = '',
  } = publicData || {};

  const houseRules = (
    <>
      <p>Age Requirement : {ageRequirement}</p>
      <p>Events Allowed : {capitalizeFirstLetter(eventsAllowed)}</p>
      <p>Smoking Allowed : {capitalizeFirstLetter(smokingAllowed)}</p>
      <p>Pets Allowed : {capitalizeFirstLetter(petsAllowed)}</p>
      <p>Minimum Stay : {mimimumStay} days</p>
      <p>Maximum Stay : {maximumStay} days</p>
    </>
  );

  const additionalRules = (
    <>
      <p>{publicData?.additionalRules}</p>
    </>
  );

  const safetyAndProperty = (
    <>
      <p>Carbon monoxide alarm not reported</p>
      <p>Smoke alarm not reported</p>
      <p>Nearby lake, river, other body of water</p>
    </>
  );

  const cancellationPolicy = (
    <>
      <p>
        <ul>
          <li>
            {' '}
            Guests get a 100% refund if they cancel at least 30 days before their check-in date.
          </li>
          <li>
            {' '}
            If they cancel 14 days before check-in, they get a 50% refund (excluding the service
            fee).
          </li>
          <li> Cancellations less than 14 days before check-in are non-refundable.</li>
        </ul>
      </p>
    </>
  );

  return {
    houseRules,
    additionalRules,
    // safetyAndProperty,
    cancellationPolicy,
  };
}

function SectionRules({ isTabLayout, title, publicData }) {
  const rulesContent = generateRulesContent(publicData);

  function renderRulesBlock(title, content) {
    return <RulesBlock title={title}>{content}</RulesBlock>;
  }

  return (
    <section className={css.thingsToKnowSec}>
      <H4 as="h1" className={css.orderPanelTitle}>
        Things to know about {title}
      </H4>
      {isTabLayout ? (
        <div className={css.mobileThingsToKnowSlider}>
          <Slider {...gallerySettings}>
            {renderRulesBlock('House rules', rulesContent.houseRules)}
            {renderRulesBlock('Additional Rules', rulesContent.additionalRules)}
            {/* {renderRulesBlock('Safety & property', rulesContent.safetyAndProperty)} */}
            {renderRulesBlock('Cancellation policy', rulesContent.cancellationPolicy)}
          </Slider>
        </div>
      ) : (
        <div className={css.thingsToKnow}>
          {renderRulesBlock('House rules', rulesContent.houseRules)}
          {renderRulesBlock('Additional Rules', rulesContent.additionalRules)}
          {/* {renderRulesBlock('Safety & property', rulesContent.safetyAndProperty)} */}
          {renderRulesBlock('Cancellation policy', rulesContent.cancellationPolicy)}
        </div>
      )}
    </section>
  );
}

export default SectionRules;
